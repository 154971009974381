import {
  NotBlankErrors,
  Fields,
  Loose,
  Login,
  ActivatePassword,
  // AccidentStage2,
  // Profile,
  UpdateProfile,
  updatePassword,
  UpdateFuelAndMileage,
  TripUpdateData
} from "./CustomTypes";
import { Storage } from "@ionic/storage";
import moment from "moment";
import { Geolocation } from '@capacitor/geolocation';

const store = new Storage();
store.create();

import api from "./ApiProvider";

class Helpers {
  public token: any;
  public userId: any;
  public userEmail: any;
  public userFullname: any;
  public userContracts: any;
  public myCar: any;
  public loggedIn: boolean;

  constructor() {
    this.loggedIn = false;
  }

  validateNotNull(field: any) {
    return field !== null;
  }

  validateEmail(email: string) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(String(email).toLowerCase());
  }

  validateNotBlank(field: string) {
    return field !== "";
  }

  validateNotZero(field: number) {
    return field > 0;
  }

  validateNotBlankMultiple(object: Loose, fields: Fields[]) {
    const errors = [] as NotBlankErrors[];

    fields.forEach((item, index) => {
      if (object[item.field] === "") {
        errors.push({
          field: item.field,
          index: index,
          message: "This field can not be blank",
        });
      }
    });

    return errors;
  }

  validateNotZeroMultiple(object: Loose, fields: Fields[]) {
    const errors = [] as NotBlankErrors[];

    fields.forEach((item, index) => {
      if (object[item.field] <= 0) {
        errors.push({
          field: item.field,
          index: index,
          message: "This field must be greater than 0",
        });
      }
    });

    return errors;
  }

  async attemptLogin(data: Login) {
    const response = await api.attemptLogin(data);

    if (response.status === 200) {
      this.token = response.data.token;

      await store.set("username", data.username);
      await store.set("token", response.data.token);

      return true;
    }

    return false;
  }

  async activatePassword(data: ActivatePassword) {
    const response = await api.activatePassword(data);

    if (response.status === 200) {
      return true;
    }

    return false;
  }

  async attemptAutoLogin() {
    const token = await this.getToken();

    if (!token) {
      return false;
    }

    this.token = token;

    return await this.getMe();
  }

  async getToken() {
    return await store.get("token");
  }

  async getMe() {
    // const token = await this.getToken();
    const response = await api.getMe(this.token);

    if (typeof response.data !== "object") {
      return false;
    }

    if (! response.data.active) {
      return false;
    }

    this.userId = response.data.id;
    this.userEmail = response.data.email;
    this.userFullname = response.data.fullname;
    this.userContracts = response.data.contracts;
    this.loggedIn = true;

    if (response.data.mycars.length) {
      const myCarResponse = await api.getCar(
        response.data.mycars[0],
        this.token
      );

      if (myCarResponse.data) {
        this.myCar = myCarResponse.data;
      }
    }

    return true;
  }

  async updateMileage(mileage: number) {
    const response = await api.updateMileage(
      {
        id: this.myCar.id,
        mileage: Number(mileage)
      },
      this.token
    );

    if (response.status === 200) {
      const diff = Number(mileage) - this.myCar.mileage;
      this.myCar = response.data;

      return diff;
    }

    return false;
  }

  async updateFuelAndMileage(data: UpdateFuelAndMileage) {
    return await api.updateFuelAndMileage(data, this.token);
  }

  async getContractData() {
    return await api.getContractData(this.userContracts[0], this.token);
  }

  async reportAccidentStage1(incidentData: Loose) {
    const momentDate = moment(incidentData.date);
    const data = {
      date: momentDate.format("YYYY-MM-DD"), // YYYY-MM-DD
      time: momentDate.format("HH:mm"), // HH:mm
      jaamaTypeOfClaimId: incidentData.typeOfDamageId,
      isDrivable: incidentData.isDrivable,
      description: incidentData.description, // Description of Incident
      images: incidentData.images,
      myCar: `/v1/mycars/${this.myCar.id}`,
      userId: `/v1/users/${this.userId}`,
    };

    return await api.reportAccidentStage1(data, this.token);
  }

//   async reportAccidentStage2(data: AccidentStage2) {
//     return await api.reportAccidentStage2(data, this.token);
//   }

  async getTypeOfDamageOptions() {
    return await api.getTypeOfDamageOptions(this.token);
  }

//   async reportAccidentComplete(id: string) {
//     return await api.reportAccidentComplete(id, this.token);
//   }

  // async createProfile(data: Profile) {
  //   const response = {
  //     status: 0,
  //     message: "",
  //   };

  //   // CREATE USER
  //   const createProfileResponse = await api.createProfile(data);

  //   if (createProfileResponse.status !== 201) {
  //     response.status = createProfileResponse.status ?? 500;
  //     response.message = createProfileResponse.data.message ?? "Error";

  //     return response;
  //   }

  //   // LOG USER IN
  //   const successfulLogin = await this.attemptLogin({
  //     username: data.email,
  //     password: data.password,
  //   });

  //   if (!successfulLogin) {
  //     response.status = 500;
  //     response.message = "Unable to Login Error";

  //     return response;
  //   }

  //   // GET USER DATA

  //   const getUserData = await this.getMe();

  //   if (!getUserData) {
  //     response.status = 500;
  //     response.message = "Unable to verify saved user";

  //     return response;
  //   }

  //   // SUBMIT CAR DATA
  //   const carResponse = await api.submitCarData(
  //     {
  //       make: data.make,
  //       model: data.model,
  //       reg: data.reg,
  //       mot: data.mot,
  //       service: data.service,
  //       mileage: 0,
  //       user: `/v1/users/${this.userId}`,
  //     },
  //     this.token
  //   );

  //   response.status = carResponse.status ?? 500;
  //   response.message = carResponse.data.message ?? "Error";

  //   if (carResponse.status !== 201) {
  //     return response;
  //   }

  //   this.myCar = carResponse.data;

  //   return response;
  // }

  async updateProfile(profileUpdate: UpdateProfile) {
    const response = {
      status: 0,
      message: "",
    };

    // UPDATE USER
    const updateProfileResponse = await api.updateProfile({
      username: profileUpdate.email,
      email: profileUpdate.email,
      fullname: profileUpdate.fullname,
      accept: profileUpdate.accept
    }, this.userId, this.token);

    if (updateProfileResponse.status !== 200) {
      response.status = updateProfileResponse.status ?? 500;
      response.message = updateProfileResponse.data.message ?? "Error";

      return response;
    }

    response.status = 200;
    response.message = updateProfileResponse.data.message ?? "";

    this.userEmail = profileUpdate.email;
    this.userFullname = profileUpdate.fullname;

    return response;
  }

  async updatePassword(data: updatePassword) {
    return api.updatePassword(data, this.userId, this.token);
  }

  getProfile() {
    return {
      fullname: this?.userFullname,
      email: this?.userEmail,
      make: this?.myCar?.make,
      model: this?.myCar?.model,
      reg: this?.myCar?.reg,
      mot: this?.myCar?.mot,
      service: this?.myCar?.service,
    };
  }

  async getUserPositionStart() {
    const location = await Geolocation.getCurrentPosition({enableHighAccuracy: true});

    return location;
  }

  async saveTrip(distance: number) {
    return await api.saveTrip({
      user: `/v1/users/${this.userId}`,
      datetime: moment().toISOString(),
      distance: distance.toFixed(2),
    }, this.token);
  }

  async getTrips() {
    return await api.getTrips(this.userId, this.token);
  }

  async updateTripDistance(data: TripUpdateData) {
    return await api.updateTripDistance(data, this.token);
  }

  async getP11dCarMakes() {
    return await api.getP11dCarMakes(this.token);
  }

  async getP11dCarModelsByMake(make:string) {
    return await api.getP11dCarModelsByMake(make, this.token);
  }

  async getP11dCarTrimsByModel(make:string, model:string) {
    return await api.getP11dCarTrimsByModel(make, model, this.token);
  }

  async getP11dValue(make:string, model:string, trim:string) {
    return await api.getP11dValue(make, model, trim, this.token);
  }

  async getChecklistData() {
    return await api.getChecklistData(this.myCar.id, this.token);
  }

  async getJaamaChecklistData() {
    return await api.getJaamaChecklistData(this.myCar.id, this.token);
  }

  async submitJaamaChecklistData(data: any) {
    this.myCar.mileage = data.Distance;

    return await api.submitJaamaChecklistData(data, this.myCar.id, this.token);
  }

  async updateChecklistQuestion(qId: number, state: boolean) {
    return await api.updateChecklistQuestion(qId, state, this.myCar.id, this.token);
  }
}

export default new Helpers();
