<template>
  <ion-menu menu-id="app-menu" content-id="main-content" side="end">
    <ion-content>
      <ion-list class="mainMenu" lines="none">
        <ion-item button @click="menuNavigation('/home')">
          <ion-label>Home</ion-label>
        </ion-item>

        <ion-item button @click="menuNavigation('/profile')">
          <ion-label>Profile</ion-label>
        </ion-item>

        <ion-item button @click="menuNavigation('/mileage')">
          <ion-label>Mileage Tracker</ion-label>
        </ion-item>

        <ion-item button @click="menuNavigation('/find-my-nearest')">
          <ion-label>Find my Nearest</ion-label>
        </ion-item>

        <ion-item button @click="menuNavigation('/driving-tips')">
          <ion-label>Driving Tips</ion-label>
        </ion-item>

        <ion-item button @click="menuNavigation('/driving-assistance')">
          <ion-label>Driving Assistance</ion-label>
        </ion-item>

        <!-- <ion-item button @click="menuNavigation('/end-of-lease-checklist')">
          <ion-label>End of Lease Checklist</ion-label>
        </ion-item> -->

        <ion-item button @click="menuNavigation('/cms-page/what-to-do-in-an-accident')">
          <ion-label>What to do in an Accident</ion-label>
        </ion-item>

        <ion-item button @click="menuNavigation('/buy-my-car')">
          <ion-label>Buy my Car</ion-label>
        </ion-item>

        <ion-item button @click="menuNavigation('/book-rental')">
          <ion-label>Book a Rental</ion-label>
        </ion-item>

        <!-- <ion-item button @click="menuNavigation('/contract-adjustment')">
          <ion-label>Contract Adjustment</ion-label>
        </ion-item> -->

        <ion-item button @click="menuNavigation('/cms-page/tch-leasing')">
          <ion-label>TCH Leasing</ion-label>
        </ion-item>

        <ion-item button @click="menuNavigation('/cms-page/terms-conditions')">
          <ion-label>Terms & Conditions</ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-menu>
</template>

<script lang="ts">
  import {
      IonMenu, IonContent, IonList, IonItem, IonLabel, menuController
  } from "@ionic/vue";
  import helper from '@/services/Helpers';

  import { defineComponent } from 'vue';

  export default defineComponent({
    components: { IonMenu, IonContent, IonList, IonItem, IonLabel },
    data() {
      return {
        loggedIn: false
      }
    },
    methods: {
      menuNavigation(url: string) {
        menuController.close('app-menu');
        this.$router.push(url);
      }
    },
    created() {
      this.$watch(
        () => this.$route.params,
        () => {
          this.loggedIn = helper.loggedIn;
        },
        { immediate: true }
      )
    }
  });
</script>
