<template>
  <master-layout pageTitle="Home Page">
    <h1 class="pageHeader text-center">Welcome to the TCH Leasing Driver App</h1>
    <div v-if="!loggedIn" class="row">
      <div class="col full">
        <div class="card" @click="navigate('/sign-in')">
          <img
            class="card_icon"
            :src="require('@/assets/images/create-profile.svg')"
          />
          <span>Activate Account / Sign In</span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="card" @click="navigate('/mileage')">
          <img
            class="card_icon"
            :src="require('@/assets/images/mileage-tracker.svg')"
          />
          <span>Mileage Tracker</span>
        </div>
      </div>

      <div class="col">
        <div class="card" @click="navigate('/fuel')">
          <img
            class="card_icon"
            :src="require('@/assets/images/fuel.svg')"
          />
          <span>Fuel Tracker</span>
        </div>
      </div>

      <div class="col">
        <div class="card" @click="navigate('/find-my-nearest')">
          <img
            class="card_icon"
            :src="require('@/assets/images/nearest.svg')"
          />
          <span>Find my Nearest...</span>
        </div>
      </div>

      <div class="col">
        <div class="card" @click="navigate('/driving-tips')">
          <img
            class="card_icon"
            :src="require('@/assets/images/driving-tips.svg')"
          />
          <span>Driving Tips</span>
        </div>
      </div>

      <!-- <div class="col">
        <div class="card" @click="navigate('/end-of-lease-checklist')">
          <img
            class="card_icon"
            :src="require('@/assets/images/lease.svg')"
          />
          <span>Vehicle Checklist</span>
        </div>
      </div> -->

      <div class="col">
        <div class="card" @click="navigate('/checklist')">
          <img
            class="card_icon"
            :src="require('@/assets/images/lease.svg')"
          />
          <span>Daily Checklist</span>
        </div>
      </div>

      <div class="col">
        <div class="card" @click="navigate('/driving-assistance')">
          <img
            class="card_icon"
            :src="require('@/assets/images/assistance.svg')"
          />
          <span>Driver Assistance</span>
        </div>
      </div>

      <div class="col">
        <div class="card" @click="navigate('/buy-my-car')">
          <img
            class="card_icon"
            :src="require('@/assets/images/buy.svg')"
          />
          <span>Buy my Car</span>
        </div>
      </div>

      <div class="col">
        <div class="card" @click="navigate('/sell-my-car')">
          <img
            class="card_icon"
            :src="require('@/assets/images/buy.svg')"
          />
          <span>Sell my Car</span>
        </div>
      </div>

      <div class="col">
        <div class="card" @click="navigate('/cms-page/tch-leasing')">
          <img
            class="card_icon"
            :src="require('@/assets/images/leasing.svg')"
          />
          <span>TCH Leasing</span>
        </div>
      </div>

      <div class="col">
        <div class="card" @click="navigate('/licence-checker')">
          <img
            class="card_icon"
            :src="require('@/assets/images/licence-checker.svg')"
          />
          <span>Licence Checker</span>
        </div>
      </div>

      <div class="col">
        <div class="card" @click="navigate('report-an-accident')">
          <img
            class="card_icon"
            :src="require('@/assets/images/report-an-accident.svg')"
          />
          <span>Report an Accident</span>
        </div>
      </div>

      <div class="col">
        <div class="card" @click="navigate('/p11d')">
          <img
            class="card_icon"
            :src="require('@/assets/images/p11d.svg')"
          />
          <span>P11D</span>
        </div>
      </div>

      <div class="col">
        <div class="card" @click="navigate('/tax-calculator')">
          <img
            class="card_icon"
            :src="require('@/assets/images/tax-calculator.svg')"
          />
          <span>Tax Calculator</span>
        </div>
      </div>

      <!-- <div class="col">
        <div class="card" @click="navigate('/driver-checklist')">
          <img
            class="card_icon"
            :src="require('@/assets/images/driver-checklist.svg')"
          />
          <span>Driver Checklist</span>
        </div>
      </div> -->

      <div class="col">
        <div class="card" @click="navigate('/trip-recorder')">
          <img
            class="card_icon"
            :src="require('@/assets/images/trip-recorder.svg')"
          />
          <span>Trip Recorder</span>
        </div>
      </div>

      <div class="col">
        <div class="card" @click="navigate('/book-rental')">
          <img
            class="card_icon"
            :src="require('@/assets/images/rent.svg')"
          />
          <span>Book a Rental</span>
        </div>
      </div>

      <div class="col">
        <div class="card" @click="navigate('/fleet-policy')">
          <img
            class="card_icon"
            :src="require('@/assets/images/edit-contract.svg')"
          />
          <span>Fleet Policy</span>
        </div>
      </div>

      <!-- <div class="col">
        <div class="card" @click="navigate('/contract-adjustment')">
          <img
            class="card_icon"
            :src="require('@/assets/images/edit-contract.svg')"
          />
          <span>Contract Adjustment</span>
        </div>
      </div> -->
    </div>
  </master-layout>
</template>

<script lang="ts">
  import { defineComponent } from "vue";
  import helper from '@/services/Helpers';

  export default defineComponent({
    components: {},
    data() {
      return {
        loggedIn: false
      }
    },
    methods: {
      navigate(url: string) {
        this.$router.push(url);
      }
    },
    created() {
      this.$watch(
        () => this.$route.params,
        async () => {
          this.loggedIn = helper.loggedIn;

          if (!this.loggedIn) {
            const response = await helper.attemptAutoLogin();

            if (response) {
              this.loggedIn = helper.loggedIn;
            }
          }
        },
        { immediate: true }
      )
    }
  });
</script>

<style lang="scss">
</style>
