<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-back-button></ion-back-button>
                </ion-buttons>

                <ion-title router-link="/home">
                    <img :src="require('@/assets/images/tch-logo.png')" :alt="pageTitle" height="40">
                </ion-title>

                <ion-buttons slot="end">
                    <ion-menu-button></ion-menu-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content class="main">
            <slot></slot>
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
import { IonPage, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, IonMenuButton, IonContent } from '@ionic/vue';
import { defineComponent } from 'vue';
export default defineComponent({
    components: {
        IonPage, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, IonMenuButton, IonContent
    },
    props: ['pageTitle']
});
</script>

<style lang="scss">
html {
    font-size: 88%;
}

.input-bg {
    background-color: white;
    border-radius: var(--border-radius);
}

ion-header ion-toolbar:first-of-type {
    --background: var(--themeColor);
    padding-top: calc(var(--ion-safe-area-top) + 15px);
    padding-bottom: 15px;
}

ion-item {
    margin-bottom: var(--margin);
    //--background: transparent;
    --border-radius: var(--border-radius);
}

ion-input {
    background-color: white;
    margin-bottom: var(--margin);
    border-radius: var(--border-radius);
    --color: black !important;

    .input-bottom {
        padding-top: 5px;
        border: none;
    }

    &.ion-invalid .input-bottom {
        justify-content: center !important;
        padding-bottom: 5px !important;
        --highlight-color-invalid: white;
        background: var(--themeColor);
        border-bottom-left-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
        border: 1px solid white;
    }
}

ion-list.mainMenu {
    padding: 0 !important;
}

ion-content {
    --padding-top: 10px;
    --padding-bottom: 10px;
    --padding-start: 10px;
    --padding-end: 10px;
    --background: var(--themeColor);
    --color: white;

    &.main {
        --background: var(--themeColor) url('../assets/images/main-bg.jpg') 0 0 / cover no-repeat fixed;
        --color: white;
    }
}

ion-menu {
    ion-item {
        --background: var(--themeColor);
        --color: white;
    }
}

ion-datetime {
    --background: #FFFFFF;
    --background-rgb: 255, 255, 255;
}

h1,
h2,
h3,
h4 {
    color: #ffffff;
    display: block;
    text-align: center;
    font-weight: bold;
    margin: 0;
}

.pageHeader {
    margin-top: 15px;
    margin-bottom: 30px;
}

.subTitle {
    a {
        color: #34abb1;
        text-decoration: none;
    }
}

.page-subtitle {
    font-size: 26px;
    line-height: 28px;
    margin: 30px 0;
    position: relative;
    overflow: hidden;

    &--border {
        position: relative;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;

        &::before,
        &::after {
            content: "";
            -webkit-box-flex: 1;
            -webkit-flex-grow: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            min-width: 30px;
            height: 2px;
            background-color: #ffffff;
        }

        & span {
            position: relative;
            padding: 0 30px;
        }
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    justify-content: stretch;

    >.col {
        max-width: 50%;
        padding-left: 15px;
        padding-right: 15px;
        flex: 1 50%;
        margin-bottom: 15px;
        align-self: stretch;

        >.card {
            border: 2px solid rgba(255, 255, 255, 0.5);
            border-radius: 8px;
            text-align: center;
            padding: 10px;
            height: 100%;
        }

        &.full {
            max-width: 100%;
        }
    }
}

form {
    ion-item {
        margin: 10px auto;
        border-radius: 5px;
    }

    // .ion-color-primary {
    //     --ion-color-base: transparant !important;
    //     --ion-color-contrast: var(--themeColor) !important;
    // }

    .datetime-header {
        --background: none;
        color: var(--color);
    }

    ion-note {
        display: none;
        background: var(--themeColor);
        width: 100%;
        color: white;
        padding: 10px 20px 6px 20px;
        margin: -15px 0 5px 0;
        border: 1px solid white;
        border-bottom-left-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
        text-align: center;
        font-size: 12px;
    }

    ion-note.ion-invalid {
        display: block;
    }

    ion-item.bordered ion-note[slot="error"] {
        border-top-left-radius: var(--border-radius);
        border-top-right-radius: var(--border-radius);
    }
}

div.cmsContent {
    background-color: #ffffff;
    padding: 20px;
    border-radius: var(--border-radius);
    color: #000000;
    line-height: 20px;
    letter-spacing: 0.5px;

    & *:first-child {
        margin-top: 0;
    }

    & *:last-child {
        margin-bottom: 0;
    }

    & h1,
    h2,
    h3,
    h4 {
        color: #000000;
        text-align: left;
    }

    & ol,
    ul {
        padding-left: 25px;
        padding-top: 10px;
        margin: 0;

        & li {
            margin-bottom: 15px;
        }
    }
}

.mainButton {
    display: block;
    width: 100%;
    text-align: center;
    padding: 15px;
    text-decoration: none;
    color: #ffffff;
    background-color: #34abb1;
    font-size: 16px;
    border: solid 2px #34abb1;
    border-radius: 5px;

    &.light {
        background-color: #b8b8b8;
        border: solid 2px #b8b8b8;
    }

    &.smaller {
      padding: 5px 10px;
    }
}

.deleteButton {
    text-align: center;
    padding: 10px 15px;
    color: white;
    background-color: red;
    border: solid 2px red;
}

// Utilities
.flex {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.widthAuto {
    width: auto;
}

.capitalize {
    text-transform: capitalize;
}

.text-center {
    text-align: center;
}

.mt10 {
    margin-top: 10px;
}

.mt15 {
    margin-top: 15px;
}

.mb10 {
    margin-bottom: 10px;
}

.mb15 {
    margin-bottom: 15px;
}

.rounded-all {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
}

.success_icon {
    display: block;
    max-width: 200px;
    margin: 0 auto;
}

.carHeader {
  background: #34abb1;
  padding: 8px 10px;
}
.carBlock {
  color: black;
  background: white;
  padding: 8px 10px;
  border-bottom: solid 1px #34abb1;

  .badge {
    background: #b8b8b8;
    margin-right: 5px;
    color: white;
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 14px;
    line-height: 1;
  }
}
</style>
