<template>
  <ion-app>
    <MenuComponent />
    <ion-modal :is-open="isOpen" ref="modal">
      <ion-header>
        <ion-toolbar>
          <ion-title>Warning</ion-title>
          <ion-buttons slot="end">
            <ion-button @click="setOpen(false)">Close</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding text-center">
        <h1>
          It is not advisable to use this app whilst driving!
        </h1>
        <p>Please make sure you are lawfully parked before use.</p>
      </ion-content>
    </ion-modal>
    <ion-router-outlet id="main-content" />
  </ion-app>
</template>

<script lang="ts">
  import { IonApp, IonButtons, IonButton, IonModal, IonHeader, IonContent, IonToolbar, IonTitle, IonRouterOutlet } from '@ionic/vue';
  import { defineComponent } from 'vue';
  import MenuComponent from './components/MenuComponent.vue';

  export default defineComponent({
    components: { IonApp, IonButtons, IonButton, IonModal, IonHeader, IonContent, IonToolbar, IonTitle, IonRouterOutlet, MenuComponent },
    data() {
      return {
        isOpen: true,
      };
    },
    methods: {
      setOpen(isOpen: boolean) {
        this.isOpen = isOpen;
      },
    },
  });
</script>

<style lang="scss" scoped>
  ion-title {
    --color: #fff;
  }
</style>
