import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row"
}
const _hoisted_2 = { class: "col full" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "col" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "col" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "col" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "col" }
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "col" }
const _hoisted_16 = ["src"]
const _hoisted_17 = { class: "col" }
const _hoisted_18 = ["src"]
const _hoisted_19 = { class: "col" }
const _hoisted_20 = ["src"]
const _hoisted_21 = { class: "col" }
const _hoisted_22 = ["src"]
const _hoisted_23 = { class: "col" }
const _hoisted_24 = ["src"]
const _hoisted_25 = { class: "col" }
const _hoisted_26 = ["src"]
const _hoisted_27 = { class: "col" }
const _hoisted_28 = ["src"]
const _hoisted_29 = { class: "col" }
const _hoisted_30 = ["src"]
const _hoisted_31 = { class: "col" }
const _hoisted_32 = ["src"]
const _hoisted_33 = { class: "col" }
const _hoisted_34 = ["src"]
const _hoisted_35 = { class: "col" }
const _hoisted_36 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_master_layout = _resolveComponent("master-layout")!

  return (_openBlock(), _createBlock(_component_master_layout, { pageTitle: "Home Page" }, {
    default: _withCtx(() => [
      _cache[34] || (_cache[34] = _createElementVNode("h1", { class: "pageHeader text-center" }, "Welcome to the TCH Leasing Driver App", -1)),
      (!_ctx.loggedIn)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", {
                class: "card",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigate('/sign-in')))
              }, [
                _createElementVNode("img", {
                  class: "card_icon",
                  src: require('@/assets/images/create-profile.svg')
                }, null, 8, _hoisted_3),
                _cache[17] || (_cache[17] = _createElementVNode("span", null, "Activate Account / Sign In", -1))
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", {
            class: "card",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.navigate('/mileage')))
          }, [
            _createElementVNode("img", {
              class: "card_icon",
              src: require('@/assets/images/mileage-tracker.svg')
            }, null, 8, _hoisted_6),
            _cache[18] || (_cache[18] = _createElementVNode("span", null, "Mileage Tracker", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", {
            class: "card",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.navigate('/fuel')))
          }, [
            _createElementVNode("img", {
              class: "card_icon",
              src: require('@/assets/images/fuel.svg')
            }, null, 8, _hoisted_8),
            _cache[19] || (_cache[19] = _createElementVNode("span", null, "Fuel Tracker", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", {
            class: "card",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.navigate('/find-my-nearest')))
          }, [
            _createElementVNode("img", {
              class: "card_icon",
              src: require('@/assets/images/nearest.svg')
            }, null, 8, _hoisted_10),
            _cache[20] || (_cache[20] = _createElementVNode("span", null, "Find my Nearest...", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", {
            class: "card",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.navigate('/driving-tips')))
          }, [
            _createElementVNode("img", {
              class: "card_icon",
              src: require('@/assets/images/driving-tips.svg')
            }, null, 8, _hoisted_12),
            _cache[21] || (_cache[21] = _createElementVNode("span", null, "Driving Tips", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", {
            class: "card",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.navigate('/checklist')))
          }, [
            _createElementVNode("img", {
              class: "card_icon",
              src: require('@/assets/images/lease.svg')
            }, null, 8, _hoisted_14),
            _cache[22] || (_cache[22] = _createElementVNode("span", null, "Daily Checklist", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", {
            class: "card",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.navigate('/driving-assistance')))
          }, [
            _createElementVNode("img", {
              class: "card_icon",
              src: require('@/assets/images/assistance.svg')
            }, null, 8, _hoisted_16),
            _cache[23] || (_cache[23] = _createElementVNode("span", null, "Driver Assistance", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", {
            class: "card",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.navigate('/buy-my-car')))
          }, [
            _createElementVNode("img", {
              class: "card_icon",
              src: require('@/assets/images/buy.svg')
            }, null, 8, _hoisted_18),
            _cache[24] || (_cache[24] = _createElementVNode("span", null, "Buy my Car", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("div", {
            class: "card",
            onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.navigate('/sell-my-car')))
          }, [
            _createElementVNode("img", {
              class: "card_icon",
              src: require('@/assets/images/buy.svg')
            }, null, 8, _hoisted_20),
            _cache[25] || (_cache[25] = _createElementVNode("span", null, "Sell my Car", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("div", {
            class: "card",
            onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.navigate('/cms-page/tch-leasing')))
          }, [
            _createElementVNode("img", {
              class: "card_icon",
              src: require('@/assets/images/leasing.svg')
            }, null, 8, _hoisted_22),
            _cache[26] || (_cache[26] = _createElementVNode("span", null, "TCH Leasing", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_23, [
          _createElementVNode("div", {
            class: "card",
            onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.navigate('/licence-checker')))
          }, [
            _createElementVNode("img", {
              class: "card_icon",
              src: require('@/assets/images/licence-checker.svg')
            }, null, 8, _hoisted_24),
            _cache[27] || (_cache[27] = _createElementVNode("span", null, "Licence Checker", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_25, [
          _createElementVNode("div", {
            class: "card",
            onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.navigate('report-an-accident')))
          }, [
            _createElementVNode("img", {
              class: "card_icon",
              src: require('@/assets/images/report-an-accident.svg')
            }, null, 8, _hoisted_26),
            _cache[28] || (_cache[28] = _createElementVNode("span", null, "Report an Accident", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_27, [
          _createElementVNode("div", {
            class: "card",
            onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.navigate('/p11d')))
          }, [
            _createElementVNode("img", {
              class: "card_icon",
              src: require('@/assets/images/p11d.svg')
            }, null, 8, _hoisted_28),
            _cache[29] || (_cache[29] = _createElementVNode("span", null, "P11D", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_29, [
          _createElementVNode("div", {
            class: "card",
            onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.navigate('/tax-calculator')))
          }, [
            _createElementVNode("img", {
              class: "card_icon",
              src: require('@/assets/images/tax-calculator.svg')
            }, null, 8, _hoisted_30),
            _cache[30] || (_cache[30] = _createElementVNode("span", null, "Tax Calculator", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_31, [
          _createElementVNode("div", {
            class: "card",
            onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.navigate('/trip-recorder')))
          }, [
            _createElementVNode("img", {
              class: "card_icon",
              src: require('@/assets/images/trip-recorder.svg')
            }, null, 8, _hoisted_32),
            _cache[31] || (_cache[31] = _createElementVNode("span", null, "Trip Recorder", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_33, [
          _createElementVNode("div", {
            class: "card",
            onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.navigate('/book-rental')))
          }, [
            _createElementVNode("img", {
              class: "card_icon",
              src: require('@/assets/images/rent.svg')
            }, null, 8, _hoisted_34),
            _cache[32] || (_cache[32] = _createElementVNode("span", null, "Book a Rental", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_35, [
          _createElementVNode("div", {
            class: "card",
            onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.navigate('/fleet-policy')))
          }, [
            _createElementVNode("img", {
              class: "card_icon",
              src: require('@/assets/images/edit-contract.svg')
            }, null, 8, _hoisted_36),
            _cache[33] || (_cache[33] = _createElementVNode("span", null, "Fleet Policy", -1))
          ])
        ])
      ])
    ]),
    _: 1
  }))
}