import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuComponent = _resolveComponent("MenuComponent")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_MenuComponent),
      _createVNode(_component_ion_modal, {
        "is-open": _ctx.isOpen,
        ref: "modal"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, null, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("Warning")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_ion_buttons, { slot: "end" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setOpen(false)))
                      }, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createTextVNode("Close")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_content, { class: "ion-padding text-center" }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createElementVNode("h1", null, " It is not advisable to use this app whilst driving! ", -1),
              _createElementVNode("p", null, "Please make sure you are lawfully parked before use.", -1)
            ])),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["is-open"]),
      _createVNode(_component_ion_router_outlet, { id: "main-content" })
    ]),
    _: 1
  }))
}